import { Tab, TabProps, Tabs, TabsProps, styled } from '@mui/material';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import useBreakpoints from '../../hooks/useBreakpoints';
import MyBetsCountBadge from '../atoms/MyBetsCountBadge';
import Logo from '../icons/Logo';

const DESKTOP_TAB_CONFIG = [
  { label: 'Sports', path: '/sports', icon: undefined },
  { label: 'In-Play', path: '/in-play' },
  {
    label: 'Casino',
    path: '/casino',
  },
  {
    label: 'My Bets',
    path: '/my-bets',
    icon: <MyBetsCountBadge />,
  },
];

const MOBILE_TAB_CONFIG = [
  { label: 'Sports', path: '/sports' },
  { label: 'Live', path: '/in-play' },
  { label: '', path: '/sports', icon: <Logo size='small' /> },
  { label: 'Casino', path: '/casino' },
  { label: 'Bets', path: '/my-bets', icon: <MyBetsCountBadge /> },
];

const StyledTabs = styled(({ indicatorWidth, ...props }: TabsProps & { indicatorWidth?: number }) => (
  <Tabs
    {...props}
    TabIndicatorProps={{
      children: <span className='MuiTabs-indicatorSpan' />,
    }}
  />
))(({ theme, indicatorWidth }) => ({
  position: 'absolute',
  left: '50%',
  transform: 'translate(-50%, 0)',
  overflow: 'visible',
  '& .MuiTabs-scroller': {
    overflow: 'visible !important', // To fix: MUI added overflow: hidden using html style prop
  },
  '& .MuiTabs-indicator': {
    background: theme.palette.primary.main,
    display: 'flex',
    justifyContent: 'center',
    height: 2,
    [theme.breakpoints.up('md')]: {
      bottom: 6,
    },
  },
  '& .MuiTabs-indicatorSpan': {
    maxWidth: indicatorWidth ?? '100%',
    width: '100%',
    backgroundColor: theme.palette.secondary.main,
  },
  '& .MuiTab-root': {
    overflow: 'visible',
    minWidth: 12,
    [theme.breakpoints.down('md')]: {
      fontSize: 10,
      letterSpacing: '0.5px',
      padding: 8,
    },
    opacity: 1,
    '&:not(.Mui-selected):hover': {
      color: theme.palette.info.main,
    },
  },
}));

const StyledTab = styled((props: TabProps) => <Tab disableRipple {...props} />)(({ theme }) => ({
  ...theme.typography.body2,
  minHeight: 48,
  textTransform: 'none',
  whiteSpace: 'nowrap',
}));

const HeaderTabNavigation = () => {
  const [selectedTab, setSelectedTab] = useState<number | false>(0);
  const navigate = useNavigate();
  const location = useLocation();

  const { isMobile } = useBreakpoints();

  const tabs = isMobile ? MOBILE_TAB_CONFIG : DESKTOP_TAB_CONFIG;

  const handleChange = (_event: React.SyntheticEvent, selectedTab: number) => {
    setSelectedTab(selectedTab);
    navigate(tabs[selectedTab].path);
  };

  const handleTabClick = (_event: React.MouseEvent<HTMLDivElement>, tabIndex: number) => {
    if (tabIndex === selectedTab) {
      handleChange(_event, tabIndex);
    }
  };

  useEffect(() => {
    const currentTab = tabs.findIndex((tab) => location.pathname.startsWith(tab.path));
    setSelectedTab(currentTab === -1 ? false : currentTab);
  }, [location.pathname, tabs]);

  return (
    <StyledTabs value={selectedTab} onChange={handleChange} indicatorWidth={46} textColor='inherit'>
      {tabs.map((tab, index) => (
        <StyledTab
          key={tab.label}
          label={tab.label}
          value={index}
          icon={tab.icon}
          onClick={(e) => handleTabClick(e, index)}
        />
      ))}
    </StyledTabs>
  );
};

export default HeaderTabNavigation;
